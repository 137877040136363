var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-lg-12" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-lg-4" }, [
                  _c("div", { staticClass: "media" }, [
                    _c("div", { staticClass: "mr-3" }),
                    _c("div", { staticClass: "media-body align-self-center" }, [
                      _c("div", { staticClass: "text-muted" }, [
                        _c("p", { staticClass: "mb-2" }, [
                          _vm._v("Welcome to skote dashboard")
                        ]),
                        _c("h5", { staticClass: "mb-1" }, [
                          _vm._v("Henry wells")
                        ]),
                        _c("p", { staticClass: "mb-0" }, [
                          _vm._v("UI / UX Designer")
                        ])
                      ])
                    ])
                  ])
                ]),
                _c("div", { staticClass: "col-lg-4 align-self-center" }, [
                  _c("div", { staticClass: "text-lg-center mt-4 mt-lg-0" }, [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-4" }, [
                        _c("div", [
                          _c(
                            "p",
                            { staticClass: "text-muted text-truncate mb-2" },
                            [_vm._v("Total Projects")]
                          ),
                          _c("h5", { staticClass: "mb-0" }, [_vm._v("48")])
                        ])
                      ]),
                      _c("div", { staticClass: "col-4" }, [
                        _c("div", [
                          _c(
                            "p",
                            { staticClass: "text-muted text-truncate mb-2" },
                            [_vm._v("Projects")]
                          ),
                          _c("h5", { staticClass: "mb-0" }, [_vm._v("40")])
                        ])
                      ]),
                      _c("div", { staticClass: "col-4" }, [
                        _c("div", [
                          _c(
                            "p",
                            { staticClass: "text-muted text-truncate mb-2" },
                            [_vm._v("Clients")]
                          ),
                          _c("h5", { staticClass: "mb-0" }, [_vm._v("18")])
                        ])
                      ])
                    ])
                  ])
                ]),
                _c("div", { staticClass: "col-lg-4 d-none d-lg-block" }, [
                  _c(
                    "div",
                    { staticClass: "clearfix mt-4 mt-lg-0" },
                    [
                      _c(
                        "b-dropdown",
                        {
                          staticClass: "float-right",
                          attrs: { right: "", variant: "primary" },
                          scopedSlots: _vm._u([
                            {
                              key: "button-content",
                              fn: function() {
                                return [
                                  _c("i", {
                                    staticClass: "bx bxs-cog align-middle mr-1"
                                  }),
                                  _vm._v(" Setting ")
                                ]
                              },
                              proxy: true
                            }
                          ])
                        },
                        [
                          _c("b-dropdown-item", [_vm._v("Action")]),
                          _c("b-dropdown-item", [_vm._v("Another action")]),
                          _c("b-dropdown-item", [_vm._v("Something else")])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ])
              ])
            ])
          ])
        ])
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-xl-4" }, [
          _c("div", { staticClass: "card bg-soft-primary" }, [
            _c("div", [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-7" }, [
                  _c("div", { staticClass: "text-primary p-3" }, [
                    _c("h5", { staticClass: "text-primary" }, [
                      _vm._v("Welcome Back !")
                    ]),
                    _c("p", [_vm._v("Skote Saas Dashboard")]),
                    _c("ul", { staticClass: "pl-3 mb-0" }, [
                      _c("li", { staticClass: "py-1" }, [
                        _vm._v("7 + Layouts")
                      ]),
                      _c("li", { staticClass: "py-1" }, [
                        _vm._v("Multiple apps")
                      ])
                    ])
                  ])
                ]),
                _c("div", { staticClass: "col-5 align-self-end" }, [
                  _c("img", {
                    staticClass: "img-fluid",
                    attrs: {
                      src: require("@/assets/images/profile-img.png"),
                      alt: ""
                    }
                  })
                ])
              ])
            ])
          ])
        ]),
        _c("div", { staticClass: "col-xl-8" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-sm-4" }, [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-body" }, [
                  _c("div", { staticClass: "d-flex align-items-center mb-3" }, [
                    _c("div", { staticClass: "avatar-xs mr-3" }, [
                      _c(
                        "span",
                        {
                          staticClass:
                            "avatar-title rounded-circle bg-soft-primary text-primary font-size-18"
                        },
                        [_c("i", { staticClass: "bx bx-copy-alt" })]
                      )
                    ]),
                    _c("h5", { staticClass: "font-size-14 mb-0" }, [
                      _vm._v("Orders")
                    ])
                  ]),
                  _c("div", { staticClass: "text-muted mt-4" }, [
                    _c("h4", [
                      _vm._v(" 1,452 "),
                      _c("i", {
                        staticClass: "mdi mdi-chevron-up ml-1 text-success"
                      })
                    ]),
                    _c("div", { staticClass: "d-flex" }, [
                      _c(
                        "span",
                        {
                          staticClass: "badge badge-soft-success font-size-12"
                        },
                        [_vm._v("+ 0.2%")]
                      ),
                      _c("span", { staticClass: "ml-2 text-truncate" }, [
                        _vm._v("From previous period")
                      ])
                    ])
                  ])
                ])
              ])
            ]),
            _c("div", { staticClass: "col-sm-4" }, [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-body" }, [
                  _c("div", { staticClass: "d-flex align-items-center mb-3" }, [
                    _c("div", { staticClass: "avatar-xs mr-3" }, [
                      _c(
                        "span",
                        {
                          staticClass:
                            "avatar-title rounded-circle bg-soft-primary text-primary font-size-18"
                        },
                        [_c("i", { staticClass: "bx bx-archive-in" })]
                      )
                    ]),
                    _c("h5", { staticClass: "font-size-14 mb-0" }, [
                      _vm._v("Revenue")
                    ])
                  ]),
                  _c("div", { staticClass: "text-muted mt-4" }, [
                    _c("h4", [
                      _vm._v(" $ 28,452 "),
                      _c("i", {
                        staticClass: "mdi mdi-chevron-up ml-1 text-success"
                      })
                    ]),
                    _c("div", { staticClass: "d-flex" }, [
                      _c(
                        "span",
                        {
                          staticClass: "badge badge-soft-success font-size-12"
                        },
                        [_vm._v("+ 0.2%")]
                      ),
                      _c("span", { staticClass: "ml-2 text-truncate" }, [
                        _vm._v("From previous period")
                      ])
                    ])
                  ])
                ])
              ])
            ]),
            _c("div", { staticClass: "col-sm-4" }, [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-body" }, [
                  _c("div", { staticClass: "d-flex align-items-center mb-3" }, [
                    _c("div", { staticClass: "avatar-xs mr-3" }, [
                      _c(
                        "span",
                        {
                          staticClass:
                            "avatar-title rounded-circle bg-soft-primary text-primary font-size-18"
                        },
                        [_c("i", { staticClass: "bx bx-purchase-tag-alt" })]
                      )
                    ]),
                    _c("h5", { staticClass: "font-size-14 mb-0" }, [
                      _vm._v("Average Price")
                    ])
                  ]),
                  _c("div", { staticClass: "text-muted mt-4" }, [
                    _c("h4", [
                      _vm._v(" $ 16.2 "),
                      _c("i", {
                        staticClass: "mdi mdi-chevron-up ml-1 text-success"
                      })
                    ]),
                    _c("div", { staticClass: "d-flex" }, [
                      _c(
                        "span",
                        {
                          staticClass: "badge badge-soft-warning font-size-12"
                        },
                        [_vm._v("0%")]
                      ),
                      _c("span", { staticClass: "ml-2 text-truncate" }, [
                        _vm._v("From previous period")
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ])
        ])
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-xl-8" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body" }, [
              _c("div", { staticClass: "clearfix" }, [
                _c("div", { staticClass: "float-right" }, [
                  _c("div", { staticClass: "input-group input-group-sm" }, [
                    _c(
                      "select",
                      { staticClass: "custom-select custom-select-sm" },
                      [
                        _c("option", { attrs: { selected: "" } }, [
                          _vm._v("Jan")
                        ]),
                        _c("option", { attrs: { value: "1" } }, [
                          _vm._v("Dec")
                        ]),
                        _c("option", { attrs: { value: "2" } }, [
                          _vm._v("Nov")
                        ]),
                        _c("option", { attrs: { value: "3" } }, [_vm._v("Oct")])
                      ]
                    ),
                    _c("div", { staticClass: "input-group-append" }, [
                      _c("label", { staticClass: "input-group-text" }, [
                        _vm._v("Month")
                      ])
                    ])
                  ])
                ]),
                _c("h4", { staticClass: "card-title mb-4" }, [
                  _vm._v("Earning")
                ])
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-lg-4" }, [
                  _c("div", { staticClass: "text-muted" }, [
                    _c("div", { staticClass: "mb-4" }, [
                      _c("p", [_vm._v("This month")]),
                      _c("h4", [_vm._v("$2453.35")]),
                      _c("div", [
                        _c(
                          "span",
                          {
                            staticClass:
                              "badge badge-soft-success font-size-12 mr-1"
                          },
                          [_vm._v("+ 0.2%")]
                        ),
                        _vm._v(" From previous period ")
                      ])
                    ]),
                    _c("div", [
                      _c(
                        "a",
                        {
                          staticClass: "btn btn-primary btn-sm",
                          attrs: { href: "javascript: void(0);" }
                        },
                        [
                          _vm._v(" View Details "),
                          _c("i", { staticClass: "mdi mdi-chevron-right ml-1" })
                        ]
                      )
                    ]),
                    _c("div", { staticClass: "mt-4" }, [
                      _c("p", { staticClass: "mb-2" }, [_vm._v("Last month")]),
                      _c("h5", [_vm._v("$2281.04")])
                    ])
                  ])
                ]),
                _c(
                  "div",
                  { staticClass: "col-lg-8" },
                  [
                    _c("apexchart", {
                      staticClass: "apex-charts",
                      attrs: {
                        dir: "ltr",
                        height: "320",
                        series: _vm.earningLineChart.series,
                        options: _vm.earningLineChart.chartOptions
                      }
                    })
                  ],
                  1
                )
              ])
            ])
          ])
        ]),
        _c("div", { staticClass: "col-xl-4" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body" }, [
              _c("h4", { staticClass: "card-title mb-4" }, [
                _vm._v("Sales Analytics")
              ]),
              _c(
                "div",
                [
                  _c("apexchart", {
                    staticClass: "apex-charts",
                    attrs: {
                      dir: "ltr",
                      height: "240",
                      series: _vm.salesAnalyticsDonutChart.series,
                      options: _vm.salesAnalyticsDonutChart.chartOptions
                    }
                  })
                ],
                1
              ),
              _c("div", { staticClass: "text-center text-muted" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-4" }, [
                    _c("div", { staticClass: "mt-4" }, [
                      _c("p", { staticClass: "mb-2 text-truncate" }, [
                        _c("i", {
                          staticClass: "mdi mdi-circle text-primary mr-1"
                        }),
                        _vm._v(" Product A ")
                      ]),
                      _c("h5", [_vm._v("$ 2,132")])
                    ])
                  ]),
                  _c("div", { staticClass: "col-4" }, [
                    _c("div", { staticClass: "mt-4" }, [
                      _c("p", { staticClass: "mb-2 text-truncate" }, [
                        _c("i", {
                          staticClass: "mdi mdi-circle text-success mr-1"
                        }),
                        _vm._v(" Product B ")
                      ]),
                      _c("h5", [_vm._v("$ 1,763")])
                    ])
                  ]),
                  _c("div", { staticClass: "col-4" }, [
                    _c("div", { staticClass: "mt-4" }, [
                      _c("p", { staticClass: "mb-2 text-truncate" }, [
                        _c("i", {
                          staticClass: "mdi mdi-circle text-danger mr-1"
                        }),
                        _vm._v(" Product C ")
                      ]),
                      _c("h5", [_vm._v("$ 973")])
                    ])
                  ])
                ])
              ])
            ])
          ])
        ])
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-xl-4" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body pb-0" }, [
              _c("div", { staticClass: "clearfix" }, [
                _c("div", { staticClass: "float-right" }, [
                  _c("div", { staticClass: "input-group input-group-sm" }, [
                    _c(
                      "select",
                      { staticClass: "custom-select custom-select-sm" },
                      [
                        _c("option", { attrs: { selected: "" } }, [
                          _vm._v("Jan")
                        ]),
                        _c("option", { attrs: { value: "1" } }, [
                          _vm._v("Dec")
                        ]),
                        _c("option", { attrs: { value: "2" } }, [
                          _vm._v("Nov")
                        ]),
                        _c("option", { attrs: { value: "3" } }, [_vm._v("Oct")])
                      ]
                    ),
                    _c("div", { staticClass: "input-group-append" }, [
                      _c("label", { staticClass: "input-group-text" }, [
                        _vm._v("Month")
                      ])
                    ])
                  ])
                ]),
                _c("h4", { staticClass: "card-title mb-4" }, [
                  _vm._v("Top Selling product")
                ])
              ]),
              _c("div", { staticClass: "text-muted text-center" }, [
                _c("p", { staticClass: "mb-2" }, [_vm._v("Product A")]),
                _c("h4", [_vm._v("$ 6385")]),
                _c("p", { staticClass: "mt-4 mb-0" }, [
                  _c(
                    "span",
                    {
                      staticClass: "badge badge-soft-success font-size-11 mr-2"
                    },
                    [
                      _vm._v(" 0.6% "),
                      _c("i", { staticClass: "mdi mdi-arrow-up" })
                    ]
                  ),
                  _vm._v(" From previous period ")
                ])
              ]),
              _c("div", { staticClass: "table-responsive mt-4 mb-0" }, [
                _c("table", { staticClass: "table table-centered" }, [
                  _c("tbody", [
                    _c("tr", [
                      _c("td", [
                        _c("h5", { staticClass: "font-size-14 mb-1" }, [
                          _vm._v("Product A")
                        ]),
                        _c("p", { staticClass: "text-muted mb-0" }, [
                          _vm._v("Neque quis est")
                        ])
                      ]),
                      _c(
                        "td",
                        [
                          _c("Sellingchart", {
                            attrs: { seriesvalue: 37, Chartcolor: "#556ee6" }
                          })
                        ],
                        1
                      ),
                      _c("td", [
                        _c("p", { staticClass: "text-muted mb-1" }, [
                          _vm._v("Sales")
                        ]),
                        _c("h5", { staticClass: "mb-0" }, [_vm._v("37 %")])
                      ])
                    ]),
                    _c("tr", [
                      _c("td", [
                        _c("h5", { staticClass: "font-size-14 mb-1" }, [
                          _vm._v("Product B")
                        ]),
                        _c("p", { staticClass: "text-muted mb-0" }, [
                          _vm._v("Quis autem iure")
                        ])
                      ]),
                      _c(
                        "td",
                        [
                          _c("Sellingchart", {
                            attrs: { seriesvalue: 72, Chartcolor: "#34c38f" }
                          })
                        ],
                        1
                      ),
                      _c("td", [
                        _c("p", { staticClass: "text-muted mb-1" }, [
                          _vm._v("Sales")
                        ]),
                        _c("h5", { staticClass: "mb-0" }, [_vm._v("72 %")])
                      ])
                    ]),
                    _c("tr", [
                      _c("td", [
                        _c("h5", { staticClass: "font-size-14 mb-1" }, [
                          _vm._v("Product C")
                        ]),
                        _c("p", { staticClass: "text-muted mb-0" }, [
                          _vm._v("Sed aliquam mauris.")
                        ])
                      ]),
                      _c(
                        "td",
                        [
                          _c("Sellingchart", {
                            attrs: { seriesvalue: 54, Chartcolor: "#f46a6a" }
                          })
                        ],
                        1
                      ),
                      _c("td", [
                        _c("p", { staticClass: "text-muted mb-1" }, [
                          _vm._v("Sales")
                        ]),
                        _c("h5", { staticClass: "mb-0" }, [_vm._v("54 %")])
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ])
        ]),
        _c("div", { staticClass: "col-xl-4" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body" }, [
              _c("h4", { staticClass: "card-title mb-4" }, [_vm._v("Tasks")]),
              _c("ul", { staticClass: "nav nav-pills bg-light rounded" }, [
                _c("li", { staticClass: "nav-item" }, [
                  _c(
                    "a",
                    {
                      staticClass: "nav-link active",
                      attrs: { href: "javascript: void(0);" }
                    },
                    [_vm._v("In Process")]
                  )
                ]),
                _c("li", { staticClass: "nav-item" }, [
                  _c(
                    "a",
                    {
                      staticClass: "nav-link",
                      attrs: { href: "javascript: void(0);" }
                    },
                    [_vm._v("Upcoming")]
                  )
                ])
              ]),
              _c(
                "div",
                { staticClass: "mt-4" },
                [
                  _c("simplebar", { staticStyle: { "max-height": "350px" } }, [
                    _c("div", { staticClass: "table-responsive" }, [
                      _c(
                        "table",
                        {
                          staticClass:
                            "table table-nowrap table-centered table-hover mb-0"
                        },
                        [
                          _c("tbody", [
                            _c("tr", [
                              _c("td", { staticStyle: { width: "50px" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "custom-control custom-checkbox"
                                  },
                                  [
                                    _c("input", {
                                      staticClass: "custom-control-input",
                                      attrs: {
                                        type: "checkbox",
                                        id: "customCheck1",
                                        checked: ""
                                      }
                                    }),
                                    _c("label", {
                                      staticClass: "custom-control-label",
                                      attrs: { for: "customCheck1" }
                                    })
                                  ]
                                )
                              ]),
                              _c("td", [
                                _c(
                                  "h5",
                                  {
                                    staticClass:
                                      "text-truncate font-size-14 mb-1"
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "text-dark",
                                        attrs: { href: "javascript: void(0);" }
                                      },
                                      [_vm._v(" Skote Saas Dashboard ")]
                                    )
                                  ]
                                ),
                                _c("p", { staticClass: "text-muted mb-0" }, [
                                  _vm._v("Assigned to Mark")
                                ])
                              ]),
                              _c("td", { staticStyle: { width: "90px" } }, [
                                _c("div", [
                                  _c(
                                    "ul",
                                    {
                                      staticClass:
                                        "list-inline mb-0 font-size-16"
                                    },
                                    [
                                      _c(
                                        "li",
                                        { staticClass: "list-inline-item" },
                                        [
                                          _c(
                                            "a",
                                            {
                                              staticClass: "text-success p-1",
                                              attrs: {
                                                href: "javascript: void(0);"
                                              }
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "bx bxs-edit-alt"
                                              })
                                            ]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "li",
                                        { staticClass: "list-inline-item" },
                                        [
                                          _c(
                                            "a",
                                            {
                                              staticClass: "text-danger p-1",
                                              attrs: {
                                                href: "javascript: void(0);"
                                              }
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "bx bxs-trash"
                                              })
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ])
                              ])
                            ]),
                            _c("tr", [
                              _c("td", [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "custom-control custom-checkbox"
                                  },
                                  [
                                    _c("input", {
                                      staticClass: "custom-control-input",
                                      attrs: {
                                        type: "checkbox",
                                        id: "customCheck2"
                                      }
                                    }),
                                    _c("label", {
                                      staticClass: "custom-control-label",
                                      attrs: { for: "customCheck2" }
                                    })
                                  ]
                                )
                              ]),
                              _c("td", [
                                _c(
                                  "h5",
                                  {
                                    staticClass:
                                      "text-truncate font-size-14 mb-1"
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "text-dark",
                                        attrs: { href: "javascript: void(0);" }
                                      },
                                      [_vm._v("New Landing UI")]
                                    )
                                  ]
                                ),
                                _c("p", { staticClass: "text-muted mb-0" }, [
                                  _vm._v("Assigned to Team A")
                                ])
                              ]),
                              _c("td", [
                                _c("div", [
                                  _c(
                                    "ul",
                                    {
                                      staticClass:
                                        "list-inline mb-0 font-size-16"
                                    },
                                    [
                                      _c(
                                        "li",
                                        { staticClass: "list-inline-item" },
                                        [
                                          _c(
                                            "a",
                                            {
                                              staticClass: "text-success p-1",
                                              attrs: {
                                                href: "javascript: void(0);"
                                              }
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "bx bxs-edit-alt"
                                              })
                                            ]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "li",
                                        { staticClass: "list-inline-item" },
                                        [
                                          _c(
                                            "a",
                                            {
                                              staticClass: "text-danger p-1",
                                              attrs: {
                                                href: "javascript: void(0);"
                                              }
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "bx bxs-trash"
                                              })
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ])
                              ])
                            ]),
                            _c("tr", [
                              _c("td", [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "custom-control custom-checkbox"
                                  },
                                  [
                                    _c("input", {
                                      staticClass: "custom-control-input",
                                      attrs: {
                                        type: "checkbox",
                                        id: "customCheck3"
                                      }
                                    }),
                                    _c("label", {
                                      staticClass: "custom-control-label",
                                      attrs: { for: "customCheck3" }
                                    })
                                  ]
                                )
                              ]),
                              _c("td", [
                                _c(
                                  "h5",
                                  {
                                    staticClass:
                                      "text-truncate font-size-14 mb-1"
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "text-dark",
                                        attrs: { href: "javascript: void(0);" }
                                      },
                                      [_vm._v(" Brand logo design ")]
                                    )
                                  ]
                                ),
                                _c("p", { staticClass: "text-muted mb-0" }, [
                                  _vm._v("Assigned to Janis")
                                ])
                              ]),
                              _c("td", [
                                _c("div", [
                                  _c(
                                    "ul",
                                    {
                                      staticClass:
                                        "list-inline mb-0 font-size-16"
                                    },
                                    [
                                      _c(
                                        "li",
                                        { staticClass: "list-inline-item" },
                                        [
                                          _c(
                                            "a",
                                            {
                                              staticClass: "text-success p-1",
                                              attrs: {
                                                href: "javascript: void(0);"
                                              }
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "bx bxs-edit-alt"
                                              })
                                            ]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "li",
                                        { staticClass: "list-inline-item" },
                                        [
                                          _c(
                                            "a",
                                            {
                                              staticClass: "text-danger p-1",
                                              attrs: {
                                                href: "javascript: void(0);"
                                              }
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "bx bxs-trash"
                                              })
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ])
                              ])
                            ]),
                            _c("tr", [
                              _c("td", [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "custom-control custom-checkbox"
                                  },
                                  [
                                    _c("input", {
                                      staticClass: "custom-control-input",
                                      attrs: {
                                        type: "checkbox",
                                        id: "customCheck4"
                                      }
                                    }),
                                    _c("label", {
                                      staticClass: "custom-control-label",
                                      attrs: { for: "customCheck4" }
                                    })
                                  ]
                                )
                              ]),
                              _c("td", [
                                _c(
                                  "h5",
                                  {
                                    staticClass:
                                      "text-truncate font-size-14 mb-1"
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "text-dark",
                                        attrs: { href: "javascript: void(0);" }
                                      },
                                      [_vm._v(" Blog Template UI ")]
                                    )
                                  ]
                                ),
                                _c("p", { staticClass: "text-muted mb-0" }, [
                                  _vm._v("Assigned to Dianna")
                                ])
                              ]),
                              _c("td", [
                                _c("div", [
                                  _c(
                                    "ul",
                                    {
                                      staticClass:
                                        "list-inline mb-0 font-size-16"
                                    },
                                    [
                                      _c(
                                        "li",
                                        { staticClass: "list-inline-item" },
                                        [
                                          _c(
                                            "a",
                                            {
                                              staticClass: "text-success p-1",
                                              attrs: {
                                                href: "javascript: void(0);"
                                              }
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "bx bxs-edit-alt"
                                              })
                                            ]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "li",
                                        { staticClass: "list-inline-item" },
                                        [
                                          _c(
                                            "a",
                                            {
                                              staticClass: "text-danger p-1",
                                              attrs: {
                                                href: "javascript: void(0);"
                                              }
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "bx bxs-trash"
                                              })
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ])
                              ])
                            ]),
                            _c("tr", [
                              _c("td", [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "custom-control custom-checkbox"
                                  },
                                  [
                                    _c("input", {
                                      staticClass: "custom-control-input",
                                      attrs: {
                                        type: "checkbox",
                                        id: "customCheck5"
                                      }
                                    }),
                                    _c("label", {
                                      staticClass: "custom-control-label",
                                      attrs: { for: "customCheck5" }
                                    })
                                  ]
                                )
                              ]),
                              _c("td", [
                                _c(
                                  "h5",
                                  {
                                    staticClass:
                                      "text-truncate font-size-14 mb-1"
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "text-dark",
                                        attrs: { href: "javascript: void(0);" }
                                      },
                                      [_vm._v(" Multipurpose Landing ")]
                                    )
                                  ]
                                ),
                                _c("p", { staticClass: "text-muted mb-0" }, [
                                  _vm._v("Assigned to Team B")
                                ])
                              ]),
                              _c("td", [
                                _c("div", [
                                  _c(
                                    "ul",
                                    {
                                      staticClass:
                                        "list-inline mb-0 font-size-16"
                                    },
                                    [
                                      _c(
                                        "li",
                                        { staticClass: "list-inline-item" },
                                        [
                                          _c(
                                            "a",
                                            {
                                              staticClass: "text-success p-1",
                                              attrs: {
                                                href: "javascript: void(0);"
                                              }
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "bx bxs-edit-alt"
                                              })
                                            ]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "li",
                                        { staticClass: "list-inline-item" },
                                        [
                                          _c(
                                            "a",
                                            {
                                              staticClass: "text-danger p-1",
                                              attrs: {
                                                href: "javascript: void(0);"
                                              }
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "bx bxs-trash"
                                              })
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ])
                              ])
                            ]),
                            _c("tr", [
                              _c("td", [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "custom-control custom-checkbox"
                                  },
                                  [
                                    _c("input", {
                                      staticClass: "custom-control-input",
                                      attrs: {
                                        type: "checkbox",
                                        id: "customCheck6"
                                      }
                                    }),
                                    _c("label", {
                                      staticClass: "custom-control-label",
                                      attrs: { for: "customCheck6" }
                                    })
                                  ]
                                )
                              ]),
                              _c("td", [
                                _c(
                                  "h5",
                                  {
                                    staticClass:
                                      "text-truncate font-size-14 mb-1"
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "text-dark",
                                        attrs: { href: "javascript: void(0);" }
                                      },
                                      [_vm._v(" Redesign - Landing page ")]
                                    )
                                  ]
                                ),
                                _c("p", { staticClass: "text-muted mb-0" }, [
                                  _vm._v("Assigned to Jerry")
                                ])
                              ]),
                              _c("td", [
                                _c("div", [
                                  _c(
                                    "ul",
                                    {
                                      staticClass:
                                        "list-inline mb-0 font-size-16"
                                    },
                                    [
                                      _c(
                                        "li",
                                        { staticClass: "list-inline-item" },
                                        [
                                          _c(
                                            "a",
                                            {
                                              staticClass: "text-success p-1",
                                              attrs: {
                                                href: "javascript: void(0);"
                                              }
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "bx bxs-edit-alt"
                                              })
                                            ]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "li",
                                        { staticClass: "list-inline-item" },
                                        [
                                          _c(
                                            "a",
                                            {
                                              staticClass: "text-danger p-1",
                                              attrs: {
                                                href: "javascript: void(0);"
                                              }
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "bx bxs-trash"
                                              })
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ])
                              ])
                            ]),
                            _c("tr", [
                              _c("td", [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "custom-control custom-checkbox"
                                  },
                                  [
                                    _c("input", {
                                      staticClass: "custom-control-input",
                                      attrs: {
                                        type: "checkbox",
                                        id: "customCheck7"
                                      }
                                    }),
                                    _c("label", {
                                      staticClass: "custom-control-label",
                                      attrs: { for: "customCheck7" }
                                    })
                                  ]
                                )
                              ]),
                              _c("td", [
                                _c(
                                  "h5",
                                  {
                                    staticClass:
                                      "text-truncate font-size-14 mb-1"
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "text-dark",
                                        attrs: { href: "javascript: void(0);" }
                                      },
                                      [_vm._v(" Skote Crypto Dashboard ")]
                                    )
                                  ]
                                ),
                                _c("p", { staticClass: "text-muted mb-0" }, [
                                  _vm._v("Assigned to Eric")
                                ])
                              ]),
                              _c("td", [
                                _c("div", [
                                  _c(
                                    "ul",
                                    {
                                      staticClass:
                                        "list-inline mb-0 font-size-16"
                                    },
                                    [
                                      _c(
                                        "li",
                                        { staticClass: "list-inline-item" },
                                        [
                                          _c(
                                            "a",
                                            {
                                              staticClass: "text-success p-1",
                                              attrs: {
                                                href: "javascript: void(0);"
                                              }
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "bx bxs-edit-alt"
                                              })
                                            ]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "li",
                                        { staticClass: "list-inline-item" },
                                        [
                                          _c(
                                            "a",
                                            {
                                              staticClass: "text-danger p-1",
                                              attrs: {
                                                href: "javascript: void(0);"
                                              }
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "bx bxs-trash"
                                              })
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ])
                              ])
                            ])
                          ])
                        ]
                      )
                    ])
                  ])
                ],
                1
              )
            ]),
            _c(
              "div",
              { staticClass: "card-footer bg-transparent border-top" },
              [
                _c("div", { staticClass: "text-center" }, [
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { href: "javascript: void(0);" }
                    },
                    [_vm._v(" Add new Task ")]
                  )
                ])
              ]
            )
          ])
        ]),
        _c("div", { staticClass: "col-xl-4" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body border-bottom" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-4 col-9" }, [
                  _c("h5", { staticClass: "font-size-15 mb-1" }, [
                    _vm._v("Steven Franklin")
                  ]),
                  _c("p", { staticClass: "text-muted mb-0" }, [
                    _c("i", {
                      staticClass:
                        "mdi mdi-circle text-success align-middle mr-1"
                    }),
                    _vm._v(" Active now ")
                  ])
                ]),
                _c("div", { staticClass: "col-md-8 col-3" }, [
                  _c(
                    "ul",
                    {
                      staticClass: "list-inline user-chat-nav text-right mb-0"
                    },
                    [
                      _c(
                        "li",
                        {
                          staticClass:
                            "list-inline-item d-none d-sm-inline-block"
                        },
                        [
                          _c(
                            "b-dropdown",
                            {
                              attrs: {
                                right: "",
                                "menu-class": "dropdown-menu-md",
                                variant: "white",
                                "toggle-class": "p-0 nav-btn"
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "button-content",
                                  fn: function() {
                                    return [
                                      _c("i", {
                                        staticClass: "bx bx-search-alt-2"
                                      })
                                    ]
                                  },
                                  proxy: true
                                }
                              ])
                            },
                            [
                              _c("form", { staticClass: "p-3" }, [
                                _c("div", { staticClass: "form-group m-0" }, [
                                  _c("div", { staticClass: "input-group" }, [
                                    _c("input", {
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "text",
                                        placeholder: "Search ...",
                                        "aria-label": "Recipient's username"
                                      }
                                    }),
                                    _c(
                                      "div",
                                      { staticClass: "input-group-append" },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass: "btn btn-primary",
                                            attrs: { type: "submit" }
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "mdi mdi-magnify"
                                            })
                                          ]
                                        )
                                      ]
                                    )
                                  ])
                                ])
                              ])
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "li",
                        {
                          staticClass:
                            "list-inline-item d-none d-sm-inline-block"
                        },
                        [
                          _c(
                            "b-dropdown",
                            {
                              attrs: {
                                "toggle-class": "nav-btn",
                                right: "",
                                variant: "white"
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "button-content",
                                  fn: function() {
                                    return [
                                      _c("i", { staticClass: "bx bx-cog" })
                                    ]
                                  },
                                  proxy: true
                                }
                              ])
                            },
                            [
                              _c("b-dropdown-item", [_vm._v("View Profile")]),
                              _c("b-dropdown-item", [_vm._v("Clear chat")]),
                              _c("b-dropdown-item", [_vm._v("Muted")]),
                              _c("b-dropdown-item", [_vm._v("Delete")])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "li",
                        { staticClass: "list-inline-item" },
                        [
                          _c(
                            "b-dropdown",
                            {
                              attrs: {
                                "toggle-class": "nav-btn",
                                right: "",
                                variant: "white"
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "button-content",
                                  fn: function() {
                                    return [
                                      _c("i", {
                                        staticClass:
                                          "bx bx-dots-horizontal-rounded"
                                      })
                                    ]
                                  },
                                  proxy: true
                                }
                              ])
                            },
                            [
                              _c("b-dropdown-item", [_vm._v("Action")]),
                              _c("b-dropdown-item", [_vm._v("Another action")]),
                              _c("b-dropdown-item", [_vm._v("Something else")])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  )
                ])
              ])
            ]),
            _c("div", { staticClass: "card-body pb-0" }, [
              _c("div", [
                _c("div", { staticClass: "chat-conversation" }, [
                  _c(
                    "ul",
                    { staticClass: "list-unstyled" },
                    [
                      _c(
                        "simplebar",
                        { staticStyle: { "max-height": "360px" } },
                        [
                          _c("li", [
                            _c("div", { staticClass: "chat-day-title" }, [
                              _c("span", { staticClass: "title" }, [
                                _vm._v("Today")
                              ])
                            ])
                          ]),
                          _vm._l(_vm.ChatData, function(data) {
                            return _c(
                              "li",
                              {
                                key: data.id,
                                class: { right: "" + data.align === "right" }
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "conversation-list" },
                                  [
                                    _c(
                                      "b-dropdown",
                                      {
                                        attrs: { variant: "white" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "button-content",
                                              fn: function() {
                                                return [
                                                  _c("i", {
                                                    staticClass:
                                                      "bx bx-dots-vertical-rounded"
                                                  })
                                                ]
                                              },
                                              proxy: true
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      },
                                      [
                                        _c("b-dropdown-item", [_vm._v("Copy")]),
                                        _c("b-dropdown-item", [_vm._v("Save")]),
                                        _c("b-dropdown-item", [
                                          _vm._v("Forward")
                                        ]),
                                        _c("b-dropdown-item", [
                                          _vm._v("Delete")
                                        ])
                                      ],
                                      1
                                    ),
                                    _c("div", { staticClass: "ctext-wrap" }, [
                                      _c(
                                        "div",
                                        { staticClass: "conversation-name" },
                                        [_vm._v(_vm._s(data.name))]
                                      ),
                                      _c("p", [_vm._v(_vm._s(data.message))]),
                                      _c(
                                        "p",
                                        { staticClass: "chat-time mb-0" },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "bx bx-time-five align-middle mr-1"
                                          }),
                                          _vm._v(" " + _vm._s(data.time) + " ")
                                        ]
                                      )
                                    ])
                                  ],
                                  1
                                )
                              ]
                            )
                          })
                        ],
                        2
                      )
                    ],
                    1
                  )
                ])
              ])
            ]),
            _c("div", { staticClass: "p-3 chat-input-section" }, [
              _c(
                "form",
                {
                  staticClass: "row",
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.formSubmit($event)
                    }
                  }
                },
                [
                  _c("div", { staticClass: "col" }, [
                    _c("div", { staticClass: "position-relative" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.chat.message,
                            expression: "chat.message"
                          }
                        ],
                        staticClass: "form-control chat-input",
                        class: {
                          "is-invalid":
                            _vm.submitted && _vm.$v.chat.message.$error
                        },
                        attrs: {
                          type: "text",
                          placeholder: "Enter Message..."
                        },
                        domProps: { value: _vm.chat.message },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.chat, "message", $event.target.value)
                          }
                        }
                      }),
                      _vm.submitted && _vm.$v.chat.message.$error
                        ? _c("div", { staticClass: "invalid-feedback" }, [
                            !_vm.$v.chat.message.required
                              ? _c("span", [_vm._v("This value is required.")])
                              : _vm._e()
                          ])
                        : _vm._e(),
                      _c("div", { staticClass: "chat-input-links" }, [
                        _c("ul", { staticClass: "list-inline mb-0" }, [
                          _c("li", { staticClass: "list-inline-item" }, [
                            _c(
                              "a",
                              {
                                directives: [
                                  {
                                    name: "b-tooltip",
                                    rawName: "v-b-tooltip.hover",
                                    modifiers: { hover: true }
                                  }
                                ],
                                attrs: {
                                  href: "javascript: void(0);",
                                  placement: "top",
                                  title: "Emoji"
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "mdi mdi-emoticon-happy-outline"
                                })
                              ]
                            )
                          ]),
                          _c("li", { staticClass: "list-inline-item" }, [
                            _c(
                              "a",
                              {
                                directives: [
                                  {
                                    name: "b-tooltip",
                                    rawName: "v-b-tooltip.hover",
                                    modifiers: { hover: true }
                                  }
                                ],
                                attrs: {
                                  href: "javascript: void(0);",
                                  placement: "top",
                                  title: "Images"
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "mdi mdi-file-image-outline"
                                })
                              ]
                            )
                          ]),
                          _c("li", { staticClass: "list-inline-item" }, [
                            _c(
                              "a",
                              {
                                directives: [
                                  {
                                    name: "b-tooltip",
                                    rawName: "v-b-tooltip.hover",
                                    modifiers: { hover: true }
                                  }
                                ],
                                attrs: {
                                  href: "javascript: void(0);",
                                  placement: "top",
                                  title: "Add Files"
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "mdi mdi-file-document-outline"
                                })
                              ]
                            )
                          ])
                        ])
                      ])
                    ])
                  ]),
                  _c("div", { staticClass: "col-auto" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary chat-send w-md",
                        attrs: { type: "submit" }
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "d-none d-sm-inline-block mr-2" },
                          [_vm._v("Send")]
                        ),
                        _c("i", { staticClass: "mdi mdi-send" })
                      ]
                    )
                  ])
                ]
              )
            ])
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }